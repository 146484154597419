body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 900px) {
  #secondary_nav{
    display: none;
  }
}

.handelMobileScreen{
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: -2.5px;
  font-family: cursive;
  line-height: 4rem;
}

@media only screen and (max-width: 900px) {
  #primary_nav{
    display: none;
  }
  #secondary_nav{
    display: flex;
  }
  .special-grid{
    margin-top: -15px !important;
  }
  .handelMobileScreen{
    font-size: 3rem;
    margin: 10px;
  }
}

.NavActive{
  display: block;
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 151px;
  background-color: #d17b15;
  background: #e8e9ea;
  border-radius: 40px 40px 0 0;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.NavHidden{
  display: none;
}

.visited_link{
  color: #049dc7 !important
}

.chat-thread {
  margin: 24px auto 0 auto;
  padding: 0 20px 0 0;
  list-style: none;
  overflow-x: hidden;
}

.chat-thread li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 16px 40px 16px 20px;
  margin: 0 0 20px 0;
  font: 16px/20px "Noto Sans", sans-serif;
  border-radius: 10px;
  background-color: #7cc3c65c;
}

/* Chat - Avatar */
.chat-thread li:before {
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  content: "";
}

/* Chat - Speech Bubble Arrow */
.chat-thread li:after {
  position: absolute;
  top: 15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
}

.chat-thread li:nth-child(1) {
  color: #2a09bb;
}

.chat-thread li:nth-child(2) {
  color: #f70792;
}

.chat-thread li:nth-child(3) {
  color: #f06306;
}

.chat-thread li:nth-child(4) {
  color: #00b604;
}

.chat-thread li:nth-child(odd) {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: right;
  margin-right: 80px;
}

.chat-thread li:nth-child(1):before {
  right: -80px;
  background-image: url(https://baldrove.sirv.com/dingtea_la/gallery/fd.png);
  background-position: center;
  box-shadow: rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

.chat-thread li:nth-child(odd):after {
  border-right: 15px solid transparent;
  right: -15px;
}

.chat-thread li:nth-child(even) {
  animation: show-chat-even 0.15s 1 ease-in;
  -moz-animation: show-chat-even 0.15s 1 ease-in;
  -webkit-animation: show-chat-even 0.15s 1 ease-in;
  float: left;
  margin-left: 80px;
}

.chat-thread li:nth-child(2):before {
  left: -80px;
  background-image: url(https://lh3.googleusercontent.com/a-/AOh14GjaRf5t_j0rNd1rQlqXTy36KM2ct3pQjRHcK6JCO08=w75-h75-p-rp-mo-ba2-br100);
  background-position: center;
  box-shadow: rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

.chat-thread li:nth-child(even):after {
  border-left: 15px solid transparent;
  left: -15px;
}

.chat-thread li:nth-child(3):before {
  right: -80px;
  background-image: url(https://lh3.googleusercontent.com/a-/AOh14GjaRf5t_j0rNd1rQlqXTy36KM2ct3pQjRHcK6JCO08=w75-h75-p-rp-mo-ba2-br100);
  background-position: center;
  box-shadow: rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

.chat-thread li:nth-child(4):before {
  left: -80px;
  background-image: url(https://lh3.googleusercontent.com/a-/AOh14GhJGz9Tz2B5RFZS4HpQwMAE6O4nKqDYd2gJHF91=w75-h75-p-rp-mo-br100);
  background-position: center;
  box-shadow: rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}

